<template>
    <el-dialog title="新增" :visible="visible" :close-on-click-modal="false" width="900px" @close="handleCancel">
        <el-table ref="gridTable" :data="tableData" class="refundTable" border height="350">
            <el-table-column prop="ItemNo" label="货号" header-align="center" align="center" width="150">
                <template slot-scope="{ row,$index }">
                    <el-autocomplete placeholder="请输入货号" v-model="row.ItemNo" :trigger-on-focus="false"
                        value-key="ItemNo" :fetch-suggestions="querySearchItemNo" @change="handleMatchItemNo(row, $index)"
                        @select="handleSeletedItemNo(row, $index, $event)"></el-autocomplete>
                </template>
            </el-table-column>
            <el-table-column prop="SpecName" label="规格" header-align="center" align="center">
                <template slot-scope="{ row,$index }">
                    {{ row.SpecName }}
                    <span v-if="row.ProductID > 0" class="el-icon-edit-outline hand"
                        @click="showSpecDialog(row, $index)"></span>
                </template>
            </el-table-column>
            <el-table-column prop="Num" header-align="center" align="center" label="数量" width="155">
                <template slot-scope="{ row }">
                    <el-input-number v-model.number="row.Num" :min="1" size="mini" :step="1" :precision="0"
                        @change="handleInput($event, row)" @input="handleInput($event, row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="Price" header-align="center" align="center" label="商品价" width="100">
                <template slot-scope="{ row }">
                    <el-input v-model="row.Price" v-format.float></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="SettlePrice" header-align="center" align="center" label="指定价" width="100">
                <template slot-scope="{ row }">
                    <el-input v-model="row.SettlePrice" @input="handleInput($event, row)" v-format.float></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="Payment" header-align="center" align="center" label="金额" width="100">
                <template slot-scope="{ row }">
                    {{ row.Payment | decimalFormat }}
                </template>
            </el-table-column>
            <el-table-column prop="" header-align="center" align="center" label="操作" width="80">
                <template slot-scope="{ row,$index }">
                    <el-button type="danger" @click="handleRowClick(row, $index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer">
            <span style="position: absolute; left: 0;font-size: 18px;">
                <span style="font-weight: bold;">合计</span>：
                <span style="color:#F56C6C;padding: 0 5px;">{{ form.TotalNum }}</span>双
                <span style="color:#F56C6C;padding: 0 5px;">{{ form.TotalFee }}</span>元
            </span>
            <el-button type="default" @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleSave">确定</el-button>
        </div>
        <el-dialog v-dialog-drag title="选择规格" width="400px" :visible="selectPropsDialogVisible"
            :close-on-click-modal="false" @close="selectPropsDialogVisible = false" append-to-body>
            <div v-if="SpecData.Colors.length > 0">
                <div class="div_prop_item">颜色：</div>
                <div :class="'div_prop_value' + (item.IsCheck ? ' current' : '')" @click="item.IsCheck = !item.IsCheck"
                    v-for="(item, index) in SpecData.Colors" :key="index">
                    <div :title="item.Color">{{ item.Value }}</div>
                </div>
            </div>
            <div v-if="SpecData.Sizes.length > 0">
                <div class="div_prop_item">尺码：</div>
                <div :class="'div_prop_value' + (item.IsCheck ? ' current' : '')" @click="item.IsCheck = !item.IsCheck"
                    v-for="(item, index) in SpecData.Sizes" :key="index">
                    <div :title="item.Size">{{ item.Value }}</div>
                </div>
            </div>
            <div style="color:red;font-size:16px;text-align:center;">支持多选，多选后批量新增</div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="selectPropsDialogVisible = false">关闭</el-button>
                <el-button type="primary" size="small" @click="saveSelectPropsDialog">保存</el-button>
            </div>
        </el-dialog>

    </el-dialog>
</template>

<script>
import { submit } from "@/api/base";
export default {
    name: "AddDetail",
    components: {},
    data() {
        return {
            visible: false,
            tableData: [],
            searchItems: [],
            purchaserProducts: {},
            form: {
                TradeID: 0,
                PurchaserID: "",
                PurchaserName: "",
                TotalNum: 0,
                TotalFee: 0,
            },
            data: null,//临时变量
            timeout: null,
            purchaser: null,
            selectPropsDialogVisible: false,
            SpecData: { ItemNo: null, Specs: null, Colors: [], Sizes: [] },
            timeOut: null,
        };
    },
    methods: {
        init(purchaser, tradeID) {
            var that = this;
            that.visible = true;
            that.purchaser = purchaser;
            that.form.TradeID = tradeID;
            that.form.PurchaserID = purchaser.PurchaserID;
            that.form.PurchaserName = purchaser.PurchaserName;
            that.form.TotalNum = 0;
            that.form.TotalFee = 0;
            that.$nextTick(() => {
                that.addEmptyRow();
            });
        },
        addEmptyRow() {
            this.tableData.push({
                ItemNo: "", SpecName: "", Num: 1, Price: 0, SettlePrice: 0, Payment: 0, SpecID: 0, ProductID: 0
            });
        },
        handleInput(e, row) {
            row.Payment = parseInt(row.Num) * parseFloat(row.SettlePrice);
            this.getStatistics();
        },
        getStatistics() {
            var total = 0;
            let totalNum = 0;
            if (this.tableData.length >= 0) {
                this.tableData.forEach((item) => {
                    if (item.ItemNo && item.SettlePrice) {
                        totalNum += parseInt(item.Num);
                        total += parseInt(item.Num) * parseFloat(item.SettlePrice);
                    }
                });
            }
            this.form.TotalFee = parseFloat(total.toFixed(2));
            this.form.TotalNum = totalNum;
        },
        handleRowClick(row, rowIndex) {
            if (this.tableData.length > 1) {
                var lastRow = this.tableData[rowIndex];
                if (lastRow.ItemNo == "") {
                    return;
                }
                this.tableData.splice(rowIndex, 1);
                this.getStatistics();
            }
        },
        handleSeletedItemNo(row, rowIndex, selectedRow, fromMatch = false) {
            let item = { ...selectedRow };
            item.Num = parseInt(row.Num);
            item.Payment = item.SettlePrice * item.Num;
            item.OldItemNo = item.ItemNo;
            if (!this.tableData.some((x, idx) => idx != rowIndex && x.ProductID == item.ProductID && x.SpecID == item.SpecID)) {
                this.tableData.splice(rowIndex, 1, item);
            } else {
                if (fromMatch) {
                    this.tableData.splice(rowIndex, 1);
                } else {
                    this.tableData[rowIndex].ItemNo = this.tableData[rowIndex].OldItemNo;
                }
            }
            this.getStatistics();
            this.$nextTick(() => {
                if (!this.tableData.some(x => x.ProductID == 0)) {
                    this.addEmptyRow();
                }
            });
        },
        handleMatchItemNo(row, rowIndex) {
            var that = this;
            clearTimeout(that.timeOut);
            that.timeOut = setTimeout(() => {
                let selectedRow = that.searchItems.filter((i) => i.ItemNo.toLowerCase() == row.ItemNo.toLowerCase());
                var productIdx = -1;
                if (selectedRow.length > 0) {
                    for (let i = 0; i < that.tableData.length; i++) {
                        if (i != rowIndex && that.tableData[i].ProductID == selectedRow[0].ProductID && that.tableData[i].SpecID == selectedRow[0].SpecID) {
                            productIdx = i;
                            break;
                        }
                    }
                    if (productIdx < 0) {
                        that.handleSeletedItemNo(row, rowIndex, selectedRow[0], true);
                    } else {
                        that.$baseMessage("已存在货号【" + row.ItemNo + "】的商品", "error");
                        if (that.tableData[rowIndex].ProductID <= 0) {
                            that.tableData[rowIndex].ItemNo = "";
                            that.tableData[rowIndex].Price = 0;
                            that.tableData[rowIndex].Cost = 0;
                            that.tableData[rowIndex].SettlePrice = 0;
                            that.tableData[rowIndex].Payment = 0;
                        } else {
                            that.tableData[rowIndex].ItemNo = that.tableData[rowIndex].OldItemNo;
                        }
                    }
                } else {
                    if (that.tableData[rowIndex].ProductID > 0 && that.tableData[rowIndex].OldItemNo) {
                        that.tableData[rowIndex].ItemNo = that.tableData[rowIndex].OldItemNo;
                    }
                    if (that.tableData[rowIndex].ProductID <= 0) {
                        that.tableData[rowIndex].ItemNo = "";
                        that.tableData[rowIndex].Price = 0;
                        that.tableData[rowIndex].Cost = 0;
                        that.tableData[rowIndex].SettlePrice = 0;
                        that.tableData[rowIndex].Payment = 0;
                    }
                }
                if (!that.tableData.some(x => x.ProductID == 0)) {
                    that.addEmptyRow();
                }
            }, 100);
        },
        async querySearchItemNo(query, callback) {
            var that = this;
            if (query !== '') {
                clearTimeout(that.timeout);
                that.timeout = setTimeout(async () => {//加上延时，避免段时间输入太多而请求太多次
                    await that.loadPurchaserProducts();
                    if (callback != null) {
                        var results = query ? this.searchItems.filter(x => {
                            return (x.ItemNo.toLowerCase().indexOf(query.toLowerCase()) >= 0);
                        }) : this.searchItems;
                        callback(results);
                    }
                }, 200);
            }
        },
        async loadPurchaserProducts() {
            var that = this;
            var key = that.form.PurchaserID + '_' + that.form.PurchaserName;
            if (typeof (that.purchaserProducts[key]) == 'undefined' || that.purchaserProducts[key].length <= 0) {
                const { data } = await submit("/api/stallRefund/getProducts", {
                    Keyword: '', CheckStall: false, PurchaserID: that.form.PurchaserID, PurchaserName: that.form.PurchaserName
                });
                that.purchaserProducts[key] = data;
                that.searchItems = data;
            } else {
                that.searchItems = that.purchaserProducts[key];
            }
        },
        //选择规格弹窗
        async showSpecDialog(row, rowIndex) {
            if (!this.SpecData.ItemNo || this.SpecData.ItemNo != row.ItemNo) {
                const { data, msg } = await submit("/api/stallRefund/getSpecs", {
                    ItemNo: row.ItemNo, ProductID: row.ProductID, CheckStall: false, PurchaserID: this.form.PurchaserID, PurchaserName: this.form.PurchaserName
                });
                if (msg != null && msg != '') {
                    this.$baseMessage(msg);
                    return;
                }
                this.SpecData = { ItemNo: row.ItemNo, Specs: data, Colors: [], Sizes: [] };
            }
            this.SpecData.Index = rowIndex;
            this.SpecData.Specs.forEach(spec => {
                if (spec.Color != '' && !this.SpecData.Colors.some(t => t.Value == spec.Color)) {
                    this.SpecData.Colors.push({ Value: spec.Color, IsCheck: false });
                }
                if (spec.Size != '' && !this.SpecData.Sizes.some(t => t.Value == spec.Size)) {
                    this.SpecData.Sizes.push({ Value: spec.Size, IsCheck: false });
                }
            });
            this.selectPropsDialogVisible = true;
        },
        //保存选择
        saveSelectPropsDialog() {
            var colors = this.SpecData.Colors.filter(d => d.IsCheck);
            var sizes = this.SpecData.Sizes.filter(d => d.IsCheck);
            if (this.SpecData.Colors.length > 0 && colors.length == 0) {
                this.$baseMessageBox("请选择颜色");
                return;
            }
            if (this.SpecData.Sizes.length > 0 && sizes.length == 0) {
                this.$baseMessageBox("请选择尺码");
                return;
            }
            colors = colors.map(d => d.Value);
            sizes = sizes.map(d => d.Value);
            var specs = this.SpecData.Specs.filter(s => colors.includes(s.Color) && sizes.includes(s.Size));
            if (specs.length == 0) {
                this.$baseMessageBox("选择的颜色尺码不存在");
                return;
            }
            if (specs.length == 1 || this.tableData[this.SpecData.Index].SpecID == 0) {
                let spec = { ...specs[0] };
                if (!this.tableData.some(t => t.ProductID == spec.ProductID && t.SpecID == spec.SpecID && t.Color == spec.Color && t.Size == spec.Size)) {
                    spec.Num = parseInt(this.tableData[this.SpecData.Index].Num);
                    spec.Payment = parseInt(spec.Num) * parseFloat(spec.SettlePrice);
                    this.tableData.splice(this.SpecData.Index, 1, { ...spec });
                    specs.splice(0, 1);
                }
            }
            if (specs.length > 0) {
                for (let i = 0; i < specs.length; i++) {
                    if (!this.tableData.some(t => t.ProductID == specs[i].ProductID && t.SpecID == specs[i].SpecID && t.Color == specs[i].Color && t.Size == specs[i].Size)) {
                        this.tableData.splice(this.tableData.length - 1, 0, { ...specs[i] });
                    }
                }
            }
            this.getStatistics();
            this.selectPropsDialogVisible = false;
        },
        createStateFilter(queryString) {
            return (state) => {
                return (
                    state.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
                );
            };
        },
        getFormData() {
            var data = { ...this.form };
            data.Details = [];
            this.tableData.forEach((item) => {
                if (item.ItemNo != "") {
                    if (item.Num == "" || parseInt(item.Num) <= 0) {
                        data.Error = "货号【" + item.ItemNo + "】的数量要大于0";
                    }
                    if (item.Price == "" || parseFloat(item.Price) <= 0) {
                        data.Error = "货号【" + item.ItemNo + "】的商品价要大于0";
                    }
                    if (item.SettlePrice == "" || parseFloat(item.SettlePrice) <= 0) {
                        data.Error = "货号【" + item.ItemNo + "】的指定价要大于0";
                    }
                    data.Details.push(item);
                }
            });
            return data;
        },
        async handleSave() {
            var formData = this.getFormData();
            if (formData.Error && formData.Error != "") {
                this.$baseMessage(formData.Error, "error");
                formData.Error = '';
                return;
            }
            if (formData.Details.length <= 0) {
                this.$baseMessage("请添加要新增的商品信息", "error"); return;
            }

            const { data } = await submit("/api/stallCashier/addTradeDetail", formData);
            this.$baseMessage('保存成功', "success");
            this.$emit("handleSuccess");
            this.handleCancel();
        },
        handleCancel() {
            this.tableData = [];
            this.purchaserProducts = {};
            this.searchItems = [];
            this.visible = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }

    .el-button {
        width: 100px !important;
        padding: 10px 10px !important;
    }
}

.div_prop_item {
    display: inline-block;
}

.div_prop_value {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 9px;
    margin-right: 4.5px;
    margin-left: 4.5px;
    padding: 2px;
    font-size: 14px;
    line-height: 14px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    color: #333;
    text-overflow: ellipsis;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, .06);
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    position: relative;
}

.div_prop_value.current {
    color: #409EFF;
    border-color: #409EFF;
    background-color: #fff7f3;
}

.div_prop_value.disabled {
    cursor: not-allowed;
    color: #ccc;
    background-color: rgba(0, 0, 0, .06);
}

.time {
    color: #F56C6C;
    font-size: 25px;
}

.editForm {
    .el-input {
        max-width: 140px;
    }

    ::v-deep {
        .el-form-item__label {
            padding-right: 0px;
        }
    }

    .el-radio {
        margin-left: 10px;
        margin-right: 0px;
    }
}
</style>