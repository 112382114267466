<template>
  <el-container class="detailContentContainer" id="detailContentContainer">
    <el-main>
      <el-table v-show="detailTab == 'item'" ref="itemTable" :data="detail.ItemDetails || []" border :row-class-name="getRowClass" height="100%">
        <el-table-column label="货号" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.Type == 'refund'">【退】</span>{{ row.ItemNo }}
            <span class="el-icon-edit-outline" v-if="row.Type != 'refund' && status != 'taked' && row.TakeStatus == 0" />
          </template>
        </el-table-column>
        <el-table-column prop="Num" label="数量" align="center">
          <template slot-scope="{ row }">
            {{ row.Num }}
            <span v-if="row.OutStockNum > 0">{{ "[缺" + row.OutStockNum + "]" }}</span>
            <span v-if="row.OutStockNum < 0">{{ "[加" + -row.OutStockNum + "]" }}</span>
            <span class="el-icon-edit-outline" v-if="row.Type != 'refund' && status == 'waitTake'"></span>
          </template>
        </el-table-column>
        <el-table-column prop="Price" head-cell-class-name="column_price" class-name="column_price" label="商品价" v-if="printConfig.bill.showTablePrice" align="center" />
        <el-table-column label="单价" align="center">
          <template slot-scope="{ row }">
            <el-input v-if="row.Type == 'refund' && row.TakeStatus == 0" v-model="row.SettlePrice" v-format.float></el-input>
            <span v-else>
              {{ row.SettlePrice | decimalFormat }}
              <span class="el-icon-edit-outline" v-if="row.Type != 'refund' && status == 'waitTake' && row.TakeStatus == 0" />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
          <template slot-scope="{ row }">
            {{ row.Payment | decimalFormat }}
          </template>
        </el-table-column>
      </el-table>
      <el-table v-show="detailTab == 'spec'" ref="specTable" :data="detail.Details || []" height="100%" :row-class-name="getRowClass" border>
        <el-table-column v-if="source != 'proxy'" type="selection" :width="28" align="center" :selectable="(row) => {
          return (status == 'waitTake' && row.Type != 'refund' && row.Num - row.OutStockNum - row.TakedNum != 0);
        }" />
        <el-table-column label="货号-规格" width="135" header-align="center" align="left">
          <template slot-scope="{ row }">
            <span v-if="row.Type == 'refund'">【退】</span>
            {{ row.ItemNo }}{{ row.SpecName ? "，" : "" }}{{ row.SpecName }}
          </template>
        </el-table-column>
        <el-table-column label="出库量" align="center">
          <template slot-scope="{ row }">
            {{ row.Num }}
            <span v-if="row.OutStockNum > 0">{{
              "[缺" + row.OutStockNum + "]"
            }}</span>
            <span v-if="row.OutStockNum < 0">{{
              "[加" + -row.OutStockNum + "]"
            }}</span>
            <span v-if="status == 'waitTake'" class="el-icon-edit-outline"></span>
          </template>
        </el-table-column>
        <el-table-column v-if="source != 'proxy'" label="待提" align="center" width="70">
          <template slot-scope="{ row }">
            <span v-if="status != 'waitTake' || row.Type == 'refund'">{{ row.TakeNum }}</span>
            <el-input v-else v-model.number="row.TakeNum" :disabled="row.TakeNum == 0"></el-input>
          </template>
        </el-table-column>
        <el-table-column v-if="source != 'proxy'" prop="TakedNum" label="已提" align="center" />
        <el-table-column prop="Price" head-cell-class-name="column_price" class-name="column_price" label="商品价" v-if="printConfig.bill.showTablePrice" align="center" />
        <el-table-column label="单价" align="center">
          <template slot-scope="{ row }">
            <el-input v-if="row.Type == 'refund' && row.TakeStatus == 0" v-model="row.SettlePrice" v-format.float></el-input>
            <span v-else>
              {{ row.SettlePrice | decimalFormat }}
              <span class="el-icon-edit-outline" v-if="row.Type != 'refund' && status == 'waitTake' && row.TakeStatus == 0" />
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="Payment" label="金额" align="center" />
        <el-table-column v-if="status == 'waitTake'" label="操作" width="50" align="center">
          <template slot-scope="{ row }">
            <i v-if="row.TakeStatus == 0" class="el-icon-delete" />
          </template>
        </el-table-column>
      </el-table>
      <el-table v-show="detailTab == 'record'" ref="recordTable" :data="detail.PayRecords || []" height="100%" :row-class-name="getRowClass" border>
        <el-table-column label="日期" width="125" header-align="center" align="center">
          <template slot-scope="{ row }">
            <el-tooltip class="item" :content="row['SettleTime']" effect="dark" placement="top">
              <span v-html="$options.filters.dateFormat(row['SettleTime'], '<strong>yy-MM-dd</strong> HH:mm')" />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="SettleFee" label="金额" align="center">
          <template slot-scope="{ row }">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                <div v-if="row.TakeFee > 0">提货金额：{{ row.TakeFee }}</div>
                <div v-if="row.RefundFee > 0">
                  退货金额：{{ row.RefundFee }}
                </div>
                <div v-if="row.Type == 'credit' && row.TotalDebtFee > 0">
                  累计欠款：{{ row.TotalDebtFee }}
                </div>
              </div>
              <span>{{ row.Payment }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="SettleNum" label="数量" align="center">
          <template slot-scope="{ row }">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                <div v-if="row.TakeNum > 0">提货数量：{{ row.TakeNum }}</div>
                <div v-if="row.RefundNum > 0">
                  退货数量：{{ row.RefundNum }}
                </div>
              </div>
              <span>{{ row.TakeNum - row.RefundNum }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="SettleType" label="支付方式" align="center">
          <template slot-scope="{ row }">
            <div>
              {{ row.SettleTypeDesc }}
              <i class="el-icon-edit" />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-descriptions v-show="detailTab == 'proxy'" class="proxy-table" :column="2" border>
        <template v-if="detail.Proxy">
          <el-descriptions-item label="订单号" :span="2">
            {{ detail.Proxy.Tid }}
            <el-button type="text">复制</el-button>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              收件人 </template>{{ detail.Proxy.ReceiverName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            {{ detail.Proxy.ReceiverMobile }}
            <el-button type="text">复制</el-button>
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              收货地址
            </template>
            {{ detail.Proxy.ReceiverProvince }} {{ detail.Proxy.ReceiverCity }}
            {{ detail.Proxy.ReceiverDistrict }}
            {{ detail.Proxy.ReceiverAddress }}
          </el-descriptions-item>
          <el-descriptions-item label="运费">￥
            <el-input :disabled="!param.onTrades.some((t) => t.TakeStatus < 2)" style="width: 70px" v-model="detail.Proxy.PostFee" v-format:float></el-input>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <el-popover placement="bottom-start" width="270" trigger="hover">
                <el-input style="margin-bottom: 5px" type="textarea" v-model="proxy.express" :rows="4" />
                <el-button type="primary" style="width: 100%" ref="sendExpress">{{ detail.Sender && detail.Sender.BotUid && detail.Sender.SenderUid ? "发送" : "复制" }}</el-button>
                <el-button type="primary" style="margin: 0 5px" slot="reference">物流通知</el-button>
              </el-popover>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="快递" :span="2">
            快递：<el-input style="width: calc(40% - 40px)" v-model="detail.Proxy.ExpressName" placeholder="快递名称"></el-input>
            单号：<el-input style="width: calc(60% - 70px)" v-model.number="detail.Proxy.ExpressNo"></el-input>
            <el-button type="text">复制</el-button>
          </el-descriptions-item>
        </template>
      </el-descriptions>
    </el-main>
    <el-footer height="105px">
      <el-container class="statistics">
        <el-main>
          <div>
            <span class="purchaser" :title="detail.PurchaserName">{{ detail.PurchaserName }}</span>
          </div>
          <div>
            <span style="font-weight: normal">
              {{ (detail.CreateTime || "").substring(2, 16) }}{{ detail.CreateTime ? "报单" : "" }}
            </span>
          </div>
          <div v-if="status != 'waitTake'">
            <span>
              {{ (detail.TakeTime || "").substring(2, 16) }}{{ detail.TakeTime ? "提货" : "" }}
            </span>
          </div>
        </el-main>
        <el-aside width="25%">
          <div>
            <span>应收<span class="money" :title="detail.Payment + (detail.DiscountFee && detail.DiscountFee != 0 ? `（优惠￥${detail.DiscountFee}）` : '')">
                ￥{{ detail.Payment | decimalFormat }}
                <span v-if="detail.DiscountFee && detail.DiscountFee != 0">(-{{ detail.DiscountFee | decimalFormat }})</span>
              </span>
            </span>
          </div>
          <div>
            <span>已收<span class="money" :title="detail.ReceivedPayment">￥{{ detail.ReceivedPayment | decimalFormat }}</span></span>
          </div>
          <div>
            <span>待收<span class="money" :title="detail.RestPayment">￥{{ detail.RestPayment | decimalFormat }}</span></span>
          </div>
        </el-aside>
        <el-aside width="15%">
          <div>
            <span>报单</span>
          </div>
          <div>
            <span :title="detail.Num || 0">{{ detail.Num || 0 }}</span>双
          </div>
          <div>
            <span :title="detail.TotalFee">￥{{ detail.TotalFee | decimalFormat }}</span>
          </div>
        </el-aside>
        <el-aside width="15%">
          <div><span>配货</span></div>
          <div>
            <span :title="detail.TakeNum || 0">{{ detail.TakeNum || 0 }}</span>双
          </div>
          <div>
            <span :title="detail.TakeFee">￥{{ detail.TakeFee | decimalFormat }}</span>
          </div>
        </el-aside>
        <el-aside width="15%">
          <div>
            <span>{{ param.onTrades.length == 1 && detail.Proxy ? "运费" : "退货" }}</span>
          </div>
          <div v-if="param.onTrades.length == 1 && detail.Proxy" style="height: 31px"></div>
          <div v-else>
            <span :title="detail.RefundNum || 0">{{ detail.RefundNum || 0 }}</span>双
          </div>
          <div>
            <span :title="detail.RefundFee">
              ￥{{ (param.onTrades.length == 1 && detail.Proxy ? detail.PostFee : detail.RefundFee) | decimalFormat }}
            </span>
          </div>
        </el-aside>
      </el-container>
    </el-footer>
    <el-footer height="58px" v-show="detail.PurchaserInfo && detail.PurchaserInfo.DebtFee && detail.PurchaserInfo.DebtFee != 0">
      <el-container class="dept">
        <el-aside class="title all" width="46px">累计<br />欠款</el-aside>
        <el-main class="money all">
          <span :title="detail.PurchaserInfo.DebtFee">{{ detail.PurchaserInfo.DebtFee | decimalFormat }}</span>
        </el-main>
        <el-aside class="title" width="40px">往期<br />欠款</el-aside>
        <el-aside class="money" width="12%">
          <span :title="detail.PurchaserInfo.PastDebtFee">{{ detail.PurchaserInfo.PastDebtFee | decimalFormat }}</span>
        </el-aside>
        <el-aside class="title" width="41px">今日<br />提货</el-aside>
        <el-aside class="money" width="12%">
          <span :title="detail.PurchaserInfo.TodayTakeFee - detail.PurchaserInfo.TodayRefundFee">
            {{ (detail.PurchaserInfo.TodayTakeFee - detail.PurchaserInfo.TodayRefundFee) | decimalFormat }}
          </span>
        </el-aside>
        <el-aside class="desc" width="14%">
          <div :title="detail.PurchaserInfo.TodayTakeFee">
            <span>提 {{ detail.PurchaserInfo.TodayTakeFee | decimalFormat }}</span>
          </div>
          <div :title="detail.PurchaserInfo.TodayRefundFee">
            <span>退 {{ detail.PurchaserInfo.TodayRefundFee | decimalFormat }}</span>
          </div>
        </el-aside>
        <el-aside class="title" width="41px">今日<br />还款</el-aside>
        <el-aside class="money" width="12%">
          <span :title="detail.PurchaserInfo.TodayRePayment">{{ detail.PurchaserInfo.TodayRePayment | decimalFormat }}</span>
        </el-aside>
        <el-aside class="money" width="50px" v-if="source != 'bill'">
          <el-button style="padding: 5px; font-size: 15px" type="success">还款</el-button>
        </el-aside>
      </el-container>
    </el-footer>
  </el-container>
</template>
<script>
import { submit } from "@/api/base";
import { floatFormat } from "@/utils/validate";
import { screenshotToBase64, copyImage, botSend } from "@/utils/stallBot";

export default {
  name: "cashierDetailContent",
  props: {
    dateRange: {},
    source: {
      type: String,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      printConfig: { bill: {} },
      detailTab: "item",
      param: {
        onTrades: [],
        debtInfo: {},
      },
      onTradeIds: [],
      onRefundIds: [],
      detail: { PurchaserInfo: {} },
      proxy: {
        express: "",
        expressNotice: null,
      },
    };
  },
  methods: {
    async createImage(type) {
      if (this.onTradeIds.length == 0 && this.onRefundIds.length == 0) {
        this.$baseMessage("请选择要截图的数据", "error");
        return;
      }

      //#region 获取需要转换为图像的DOM元素
      let element = document.getElementById("detailContentContainer");
      if (type == "multiple") {
        const that = this;
        await new Promise((resolve, reject) => {
          const timer = setInterval(() => {
            if (that.detail.ItemDetails && that.detail.ItemDetails.length > 0) {
              clearInterval(timer);
              resolve();
            }
          }, 500);
        });
      }
      let copyElement = element.cloneNode(true);
      copyElement.style.height = "auto";
      copyElement.style.zIndex = -1;
      copyElement.style.right = -610;
      copyElement.style.position = "absolute";
      while (copyElement.firstChild.nodeType == 8) {
        copyElement.removeChild(copyElement.firstChild);
      }

      copyElement.firstChild.style.height = "auto";
      copyElement.firstChild.firstChild.style.height = "auto";
      copyElement.firstChild.style.minHeight = "360px";
      copyElement.firstChild.firstChild.children[2].style.height = "auto";
      copyElement.firstChild.children[1].children[2].style.height = "auto";

      var elTables = copyElement.getElementsByClassName("el-table");
      for (let i = 0; i < elTables.length; i++) {
        if (elTables[i].style.display == "none") {
          continue;
        }
        var priceColumns = elTables[i].getElementsByClassName("column_price");
        if (priceColumns.length > 0) {
          let column_idx = -1;
          for (let i = priceColumns.length - 1; i >= 0; i--) {
            let element = priceColumns[i];
            if (column_idx < 0) {
              column_idx = Array.prototype.indexOf.call(
                element.parentNode.getElementsByTagName(element.tagName),
                element
              );
            }
            priceColumns[i].remove();
          }
          if (column_idx > -1) {
            let colgroups = elTables[i].getElementsByTagName("colgroup");
            for (let i = 0; i < colgroups.length; i++) {
              let cols = colgroups[i].getElementsByTagName("col");
              if (cols.length >= column_idx) {
                cols[column_idx].remove();
              }
            }
          }
        }
      }

      element.parentNode.appendChild(copyElement); // 添加到body
      //#endregion

      let dataUrl = await screenshotToBase64(copyElement);
      copyElement.remove();
      if (type == "multiple") {
        // copyImage(dataUrl);
        // return;
        let res = await botSend("img", dataUrl, {
          botType: this.detail.Sender.BotType,
          botUid: this.detail.Sender.BotUid,
          senderUid: this.detail.Sender.SenderUid,
        });
        return res;
      }
    },
    selectSpecRow(selection, row) {
      row.Selected = !row.Selected;
      row.TakeNum = row.Selected ? row.Num - row.OutStockNum - row.TakedNum : 0;
      this.detail.RestPayment = floatFormat(
        this.detail.RestPayment -
        row.LastTakeNum * row.SettlePrice +
        row.TakeNum * row.SettlePrice
      );
      this.detail.ReceivePayment = floatFormat(
        this.detail.ReceivePayment -
        row.LastTakeNum * row.SettlePrice +
        row.TakeNum * row.SettlePrice
      );
      row.LastTakeNum = row.TakeNum;
    },
    getRowClass({ row }) {
      let classNames = ["detail-row"];
      if (row.Type == "refund") {
        classNames.push("refund");
      }
      if (this.status == "waitTake" && row.Selected) {
        classNames.push("is-active");
      }
      if (row.FlagDel) {
        classNames.push("is-delete");
      }
      if (row.isHidden) {
        classNames.push("is-hidden");
      }
      return classNames.join(" ");
    },
    fetchData(param) {
      this.param = param;
      this.onTradeIds = [];
      this.onRefundIds = [];
      this.param.onTrades.forEach((trade) => {
        if (trade.TradeIDs) {
          this.onTradeIds.push(trade.TradeIDs);
        }
        if (trade.RefundIDs) {
          this.onRefundIds.push(trade.RefundIDs);
        }
      });
      this.getDetails();
    },
    async getDetails() {
      this.detail = { PurchaserInfo: {} };
      if (this.onTradeIds.length == 0 && this.onRefundIds.length == 0) {
        return;
      }
      let start =
        this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null;
      let end =
        this.dateRange && this.dateRange.length > 1 ? this.dateRange[1] : null;
      const { data } = await submit("/api/stallCashier/getDetails", {
        startTime: start,
        endTime: end,
        IsGetPurchaser: this.source != "bill",
        tradeIds: this.onTradeIds.join(","),
        refundIds: this.onRefundIds.join(","),
      });
      this.detail = data;
      if (this.status == "taked") {
        this.detail.ReceivePayment = this.detail.DebtFee;
      }
      if (this.param.debtInfo) {
        this.detail.PurchaserInfo = this.param.debtInfo;
      }
      this.detail.PurchaserName =
        (this.param.onTrades[0].PurchaserNick
          ? `(${this.param.onTrades[0].PurchaserNick})`
          : "") + this.param.onTrades[0].PurchaserName;
      this.detail.CreateTime = this.param.onTrades[0].CreateTime;
      this.detail.TakeTime = this.param.onTrades[0].TakeTime;
      for (let detail of this.detail.Details) {
        detail.Selected =
          detail.TakeStatus < 2 ? detail.TakeNum != 0 : detail.TakedNum != 0;
        detail.LastTakeNum = detail.TakeNum;
      }
      this.filterDetails();
      this.$nextTick(() => {
        for (let detail of this.detail.Details) {
          if (detail.Selected) {
            this.$refs.specTable.toggleRowSelection(detail, true);
          }
        }
      });
      this.detailJson = JSON.stringify(this.detail);
      if (this.param.onTrades.length == 1) {
        this.$emit("refreshTrade", {
          TakeNum: this.detail.TakeNum,
          RefundNum: this.detail.RefundNum,
          RestPayment: this.detail.RestPayment,
          ReceivedPayment: this.detail.ReceivedPayment,
        });
      }
      this.setProxyContent();
    },
    filterDetails() {
      if (!this.detail.Details) {
        return;
      }
      for (let i = 0; i < this.detail.ItemDetails.length; i++) {
        if (this.searchItemNo) {
          if (
            this.detail.ItemDetails[i].ItemNo.toLowerCase().includes(
              this.searchItemNo.toLowerCase()
            )
          ) {
            this.detail.ItemDetails[i].isHidden = false;
          } else {
            this.detail.ItemDetails[i].isHidden = true;
          }
        } else {
          this.detail.ItemDetails[i].isHidden = false;
        }
      }
      this.detail.ItemDetails.splice(0, 0);
      for (let i = 0; i < this.detail.Details.length; i++) {
        if (this.searchItemNo) {
          if (
            this.detail.Details[i].ItemNo.toLowerCase().includes(
              this.searchItemNo.toLowerCase()
            )
          ) {
            this.detail.Details[i].isHidden = false;
          } else {
            this.detail.Details[i].isHidden = true;
          }
        } else {
          this.detail.Details[i].isHidden = false;
        }
      }
      this.detail.Details.splice(0, 0);
    },
    setProxyContent() {
      if (this.detail.Proxy) {
        this.proxy.express =
          `【快递通知】\r\n` +
          `代发订单：${this.detail.Proxy.ReceiverName} ${this.detail.Proxy.ReceiverMobile} \r\n` +
          `${this.detail.Proxy.ExpressName
            ? "快递名称：" + this.detail.Proxy.ExpressName + "\r\n"
            : ""
          }` +
          `快递单号：${this.detail.Proxy.ExpressNo}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#detailContentContainer {
  ::v-deep {
    .el-input__inner {
      text-align: center;
    }
  }
}

.detailContentContainer {
  height: 100%;
  border-radius: 7px;
  position: relative;
  overflow-y: hidden;

  .proxy-table {
    height: 100%;
  }

  ::v-deep {
    .detail-row {
      cursor: pointer;

      td {
        padding: 5px 0;
      }

      .el-icon-edit-outline {
        color: #409eff !important;
      }

      .el-icon-delete {
        color: #ff0000 !important;
      }

      &.refund {
        .cell {
          color: #ff0000 !important;
        }
      }

      &.is-active {
        td {
          background: #e9f4ff !important;
        }
      }

      &.is-delete,
      &.is-hidden {
        display: none;
      }
    }
  }

  .operate1 {
    box-sizing: border-box;
    text-align: right;
    position: absolute;
    top: 0px;
    right: 2px;
  }

  .statistics {
    background-color: #d5eaff !important;

    &>main,
    &>aside {
      padding: 0 5px;

      &:first-of-type {
        &>div {
          text-align: left;
        }
      }

      &>div {
        line-height: 34px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &>span {
          font-weight: bold;
          font-size: 18px;
          line-height: 31px;
        }
      }
    }

    &>main {
      &>div {
        line-height: 32px;
      }
    }

    .money {
      color: red;
    }

    .purchaser {
      font-size: 30px;
    }
  }

  .dept {
    background-color: #abd4fd !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 15px;
      border: 0;
      line-height: 24px;
    }

    .title.all {
      font-weight: bold;
      font-size: 18px;
      color: black;
    }

    .money {
      color: #0075e3;
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 57px;

      &.all {
        font-size: 24px;
        line-height: 24px;
        color: red;
        border: 0;
      }
    }

    .desc {
      color: #8d8a90;
      font-size: 10px;
      max-width: 55%;

      &>div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .remark {

    &>main,
    &>aside {
      border: 0;
    }

    ::v-deep {
      .el-input-group__prepend {
        width: 30px;
      }
    }
  }

  .operate2 {
    .receive {
      ::v-deep {
        .el-input-group__prepend {
          width: 30px;
        }

        .el-input__inner {
          height: 120px;
          color: red;
          font-size: 45px;
          font-weight: bold;
        }

        .el-input-group__prepend {
          font-size: 30px;
          color: black;
          font-weight: bold;
          text-align: center;
        }
      }
    }

    .el-row {
      .el-col {
        height: 120px;
        position: relative;

        .el-tag {
          margin-left: 0;
          margin-bottom: 5px;
          width: calc(100%);
          height: calc(50% - 2.5px);
          padding: 15px 0;
          font-weight: bold;
          font-size: 16px;
          cursor: pointer;
          text-align: center;

          &--danger {
            margin-left: 5px;
            width: calc(60% - 2.5px);
          }

          &--warning {
            margin-left: 5px;
            width: calc(50% - 2.5px);
          }

          &--dark {
            position: relative;

            &::before {
              content: "\e6da";
              font-family: element-icons !important;
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }

        .el-button {
          display: inline-block;
          vertical-align: top;
          margin-bottom: 5px;
          margin-left: 0;
          width: calc(100%);
          height: calc(50% - 2.5px);
          padding: 0;
          font-weight: bold;
          font-size: 20px;

          &--default {
            width: calc(40% - 2.5px);
          }

          &--info,
          &--warning.is-plain {
            width: calc(50% - 2.5px);
            font-weight: normal;
            font-size: 10px;
          }

          &--warning.is-plain {
            margin-left: 5px;
          }
        }

        .delivery {
          width: calc(100%);
          height: calc(100%);
          font-weight: bold;
          font-size: 20px;
        }
      }
    }

    &>div {
      vertical-align: top;
    }

    .box1 {
      vertical-align: top;
      width: calc(61.5%);
      display: inline-block;
    }

    .box1_1 {
      width: calc(79.5%);
    }

    .box2 {
      display: inline-block;
      height: 120px;
      width: calc(36%);

      .el-button {
        vertical-align: top;
        margin-bottom: 5px;
        margin-left: 0px;
        width: calc(100%);
        max-width: 101px;
        height: calc(50% - 5px);
        font-weight: bold;
        font-size: 20px;
      }

      &>div {
        vertical-align: top;
        display: inline-block;
        width: calc(50% - 5px);
        padding-left: 5px;
        height: 100%;

        ::v-deep {
          .el-button--info.is-plain {
            width: calc(50% - 2.5px);
            line-height: 20px;
            margin-right: 2.5px;
            padding: 0px;
            font-weight: normal;
            font-size: 10px;
          }

          .el-button--warning.is-plain {
            width: calc(50% - 2.5px);
            margin-left: 2.5px;
            line-height: 20px;
            padding: 0px;
            font-weight: normal;
            font-size: 10px;
          }
        }
      }

      .repay {
        height: calc(100% - 5px);
        font-size: 30px;
      }
    }
  }

  #print-view {
    position: fixed;
    left: -1000px;
  }

  .stock-title {
    width: calc(100% - 40px);

    &>div {
      display: inline-block;
      min-width: 25%;
    }

    .item-title {
      text-align: center;
      width: calc(50%);

      .point {
        font-size: 20px;
        font-family: "fangsong", Courier, monospace;
        font-weight: bold;
      }
    }
  }

  ::v-deep {
    .dlg_btn {
      width: 80px;
      padding: 10px 10px;
    }

    .el-dialog__body {
      .money {
        font-size: 18px;
        font-family: "fangsong", Courier, monospace;
        font-weight: bold;
        color: red;
      }

      .el-form {
        padding-right: 0px;
      }

      .el-form-item {
        .el-input {
          max-width: 150px;
        }

        .el-date-editor--date {
          width: 150px !important;
        }

        .el-button {
          margin-left: 2px;
        }

        .el-form-item__label {
          padding: 0 2px;
        }
      }

      .out-stock {
        .el-input {
          width: 40px;
          vertical-align: top;

          .el-input__inner {
            padding: 0;
            text-align: center;
          }
        }

        .el-tag--medium {
          height: 42px;
          line-height: 42px;
          font-size: 15px;
          margin: 0 10px 5px 0;
          width: calc(25% - 10px);

          &>span {
            display: inline-block;
            width: calc(100% - 33px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
          }
        }
      }
    }
  }
}
</style>
